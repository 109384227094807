<script lang="ts" setup>
import {
  ref,
  computed,
  onMounted,
  defineAsyncComponent,
  hydrateOnInteraction,
  watch,
} from 'vue'
import { useCustomerStore } from '@/store/customer'
import { useAuthenticationStore } from '@/store/authentication'
import { useTranslationsStore } from '@/store/translations'
import { useContentStore } from '@/store/content'
import { emitter } from '@/composables/eventBus'
import { getServerState, useSSRContext } from '@ocp-zmarta/zsc-plugin-vite-ssr/vue'
import { EidProviders } from '@/header/constants/types'
import type { HeaderContent } from '@/header/types'

const ctx = import.meta.env.SSR
  ? useSSRContext()
  : {
      market: getServerState('market'),
      route: getServerState('route'),
      isElskling: getServerState('isElskling'),
    }

// Props
defineProps<{ minimal: boolean }>()

// Stores
const contentStore = useContentStore()
const customerStore = useCustomerStore()
const authentication = useAuthenticationStore()
const translationsStore = useTranslationsStore()

// Components
const ZgButton = defineAsyncComponent(async () => await import('@ocp-zmarta/zmarta-components').then((c) => c.ZgButton))
const ZgIcon = defineAsyncComponent({
  loader: async () => await import('@ocp-zmarta/zmarta-components').then((c) => c.ZgIcon),
  hydrate: hydrateOnInteraction('click'),
})

const content = computed<HeaderContent>(() => contentStore.getContent?.elements?.header ?? {})

const loading = ref(true)
const visibleMenu = ref(false)
const customerLoggedIn = ref(false)

const emit = defineEmits(['track-navigation-click'])

const customerName = computed(() => customerStore.getCustomerName ?? content.value.labels?.myAccount)
const myAccountPath = `${contentStore.getContent?.MY_ACCOUNT_PATH[translationsStore.getLocale!]}`

const toggleMenu = () => {
  visibleMenu.value = !visibleMenu.value
}

const showLogin = () => {
  emit('track-navigation-click', 'my-account.login')

  if (ctx?.isElskling) {
    authentication.mutateLoading(true)
    emitter.emit('authentication:initiate', {
      loginType: 'eid',
      provider: EidProviders.BANKID,
    })
    return
  }

  emitter.emit('login:show')
}

const logout = async () => {
  await authentication.logout()
}

const checkCustomer = () => {
  loading.value = customerStore.getCustomer.loading
  customerLoggedIn.value = customerStore.getCustomer.loggedIn
}

onMounted(() => {
  watch(() => customerStore.getCustomer.loggedIn, () => {
    checkCustomer()
  }, { immediate: true })
})
</script>

<template>
  <div
    :class="['my-account', {
      'my-account--visible': visibleMenu,
      'my-account--minimal': minimal,
      'my-account--logged-in': customerLoggedIn,
      [`my-account--${ctx?.market}`]: ctx?.market
    }]"
  >
    <zg-button
      v-if="customerLoggedIn && !loading"
      key="customer-btn"
      data-allow-mismatch
      class="customer-btn"
      :icons="{ right: 'chevron' }"
      outline
      full-width
      @click="toggleMenu"
    >
      <span class="name">{{ customerName }}</span>
    </zg-button>

    <zg-button
      v-else
      key="login-btn"
      class="login-btn"
      :icons="{ right: `bank-id-${ctx?.market}` }"
      outline
      full-width
      @click="showLogin"
    >
      <span>{{ content.labels?.login }}</span>
    </zg-button>

    <ul>
      <li>
        <a :href="myAccountPath" :class="{ 'current': ctx?.route.pathname === myAccountPath }">
          {{ content.labels?.myAccount }}
        </a>
      </li>

      <li>
        <a class="logout" @click.prevent="logout">
          {{ content.labels?.logout }}
          <zg-icon icon="logout" size="xsmall" />
        </a>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
  @import '/vue/scss/breakpoints.scss';

  .my-account {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: rem(100px);

    @include mq(medium) {
      width: rem(160px);
    }

    :deep(.login-btn),
    :deep(.customer-btn) {
      @include type-title-xxs;

      width: 100%;
      min-width: unset;
      max-width: unset;
      z-index: +1;
      height: rhythm(medium);
      gap: rem(2px);
      padding: rem(6px) rhythm(half);
      margin: 0;

      .zg-icon {
        width: rhythm(small);
        height: rhythm(small);

        svg {
          width: rhythm(small);
          height: rhythm(small);
        }
      }

      @include mq(medium) {
        @include type-title-xs;

        height: rhythm(large);
        gap: rem(4px);
        padding: rhythm(xsmall) rhythm(small);
        margin: 0;

        :deep(.zg-icon) {
          width: rem(20px);
          height: rem(20px);

          svg {
            width: rem(20px);
            height: rem(20px);
          }
        }
      }
    }

    .customer-btn {
      .name {
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100%;
        width: rem(80px);

        @include mq(medium) {
          width: rem(100px);
        }
      }

      :deep(svg) {
        transition: all 0.3s ease;
      }
    }

    ul {
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      display: flex;
      flex-direction: column;
      min-width: 100%;
      border: none;
      list-style: none;
      background-color: var(--beige);
      margin: 0;
      padding: 0;
      border-radius: 0 0 radius(box) radius(box);
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s ease;

      @include mq($until: medium) {
        display: none;
      }

      &::before {
        position: absolute;
        top: - rem(30px);
        left: 0;
        right: 0;
        background-color: var(--beige);
        height: rem(40px);
        content: '';
      }
    }

    li {
      position: relative;
      display: flex;
      flex-direction: column;
      padding: rhythm(small) 0;

      &::after {
        position: absolute;
        bottom: 0;
        left: rhythm(base);
        right: rhythm(base);
        background-color: var(--black);
        height: rem(1px);
        content: '';
      }

      &:last-child::after {
        display: none;
      }
    }

    a {
      @include type-title-xs;

      display: inline-flex;
      flex-direction: row;
      align-items: center;
      gap: rem(8px);
      padding: rhythm(small) rhythm(base);
      margin: 0 rhythm(small) 0 0;
      border: none;
      border-radius: 0 rem(99) rem(99) 0;
      white-space: nowrap;

      &:hover {
        cursor: pointer;
        color: var(--brand-500);

        svg {
          fill: var(--brand-500);
        }
      }

      &.current {
        background: var(--white);
      }

      &.logout {
        padding: rhythm(xsmall) rhythm(base);

        @include mq(large) {
          @include type-title-xxs;

          margin: 0;
        }
      }
    }
  }

  // ? ----------------------------------------------------------------------
  // modifiers
  // ? ----------------------------------------------------------------------

  .my-account--se {
    @include mq($until: $se-breakpoint-desktop) {
      &.my-account--logged-in {
        display: none;
      }

      &.my-account--minimal {
        .customer-btn {
          display: none;
        }
      }
    }
  }

  .my-account--no {
    @include mq($until: $no-breakpoint-desktop) {
      &.my-account--logged-in {
        display: none;
      }

      &.my-account--minimal {
        .customer-btn {
          display: none;
        }
      }
    }
  }

  .my-account--fi {
    @include mq($until: $fi-breakpoint-desktop) {
      &.my-account--logged-in {
        display: none;
      }

      &.my-account--minimal {
        .customer-btn {
          display: none;
        }
      }
    }
  }

  .my-account--logged-in.my-account--visible,
  .my-account--logged-in.my-account:hover {
    .customer-btn {
      border-color: var(--beige);
      background-color: var(--beige);

      :deep(svg) {
        transform: rotateZ(180deg);
      }
    }

    ul {
      opacity: 1;
      visibility: visible;
    }
  }
</style>
